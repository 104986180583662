import React, {useState} from 'react';
import Button from '@mui/material/Button';
import { storage, db } from './firebase';
import firebase from "firebase/compat/app";
import './ImageUpload.css';

function ImageUpload({username}) {
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(0);
  const [caption, setCaption] = useState('');

  const handleChange = (e) => {
    if (e.target.files[0]) {
        setImage(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    const uploadTask = storage.ref('images/' + image.name).put(image);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progress)
        },
        (error) => {
            console.log(error);
            alert(error.message);
        },
        () => {
            storage
              .ref("images")
              .child(image.name)
              .getDownloadURL()
              .then(url => {
                //post image in db
                db.collection("posts").add({
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    caption: caption,
                    imageUrl: url,
                    username: username
                });

                setProgress(0);
                setCaption("");
                setImage(null);
              });
        }
    )
  }

  return (
    <div className="imageupload">
        <progress className="imageupload__progress" value={progress} max="100"/>
        <br></br>
        <textarea className="imageupload__caption" rows="5" type="text" placeholder='Enter a caption...' onChange={event => setCaption(event.target.value)} value={caption}/>
        <br></br>
        <input type="file" onChange={handleChange} />
        <br></br>
        <Button style={{backgroundColor: "#ff00d4"}} variant="contained" onClick={handleUpload}>
            Upload
        </Button>
    </div>
  )
}

export default ImageUpload