import React, { useState, useEffect } from 'react';
import './App.css';
import knotoLogo from './knoto-clown.svg';
import knotoLogoPink from './knoto-clown-pink.svg';
import downArrow from './double-down-arrow.svg';
import Post from './Post';
import { db, auth }  from './firebase';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Input } from '@mui/material';
import ImageUpload from './ImageUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function App() {
  const [posts, setPosts] = useState([]);
  const [open, setOpen] = useState(false);
  const [openSignIn, setOpenSignIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //user logged in
        console.log(authUser);
        setUser(authUser);
      } else {
        //user has logged out
        setUser(null);
      }

      return () => {
        //clean shit up
        unsubscribe();
      }
    })
  }, [user, username]);
  
  useEffect(() => {
      db.collection('posts').orderBy('timestamp','desc').onSnapshot(snapshot => {
        setPosts(snapshot.docs.map(doc => ({
          id: doc.id,
          post: doc.data()
        })));
      })
  }, []);

  const signUp = (event) => {
    event.preventDefault();
    auth
    .createUserWithEmailAndPassword(email, password)
    .then((authUser) => {
      return authUser.user.updateProfile({
        displayName: username
      })
    })
    .catch((error) => alert(error.message))

    setOpen(false);
  };

  const signIn = (event) => {
    event.preventDefault();

    auth
      .signInWithEmailAndPassword(email, password)
      .catch((error) => alert(error.message))

    setOpenSignIn(false);
  }

  return (
    <div className="app">

      <Modal
      // login pop up
        open={open}
        onClose={() => setOpen(false)}
      >
      <Box sx={style}>
        <form className="app_signup">
          <center>
            <img 
              className="app__headerImageModal" 
              src={knotoLogoPink}
              alt=""
            />
          </center>
          <br></br>
            <Input
              placeholder="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              placeholder="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button style={{backgroundColor: "#000"}} variant="contained" type="submit" onClick={signUp}>Sign Up</Button>
          </form>
        </Box>
      </Modal>
      
      <Modal
      //sign in pop up
        open={openSignIn}
        onClose={() => setOpenSignIn(false)}
      >
      <Box sx={style}>
        <form className="app_signup">
          <center>
            <img 
              className="app__headerImageModal" 
              src={knotoLogoPink}
              alt=""
            />
          </center>
          <br></br>
            <Input
              placeholder="email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              placeholder="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button style={{backgroundColor: "#000"}} type="submit" onClick={signIn}>Sign In</Button>
          </form>
        </Box>
      </Modal>

      <div className="app__header">
        <img 
          className="app__headerImage" 
          src={knotoLogo}
          alt=""
        />

      {user ? (
        <Button style={{backgroundColor: "#000"}} variant="contained" onClick={() => auth.signOut()}>Log Out</Button>
      ): (
        <div className="app__loginContainer">
          <Button style={{backgroundColor: "#000"}} variant="contained" onClick={() => setOpenSignIn(true)}>Sign In</Button>
        </div>
      )}
      </div>
      
      <div className="app__intro">
        <div className="app__introContents">
          <h1 class="wiggly-text">“To my culture. Today is a grand day because I am able to share my new art project that will allow me to be more personal with all of you through my thoughts and art.</h1>
          <br></br>
          <h1 class="wiggly-text">Here we express the truth no matter how deep it digs. With that being said, this is an area of peace, love and pain. I know it sounds off but that’s because it is. Things are different. You're different. I’m different. So it is time for something new.</h1>
          <br></br>
          <h1 class="wiggly-text">I can not wait to share all I have and all I have been holding back for years for this day. This project will change all of the time, new faces just like new days. I thought I heard it breathing and chattering, so I thought it would be a good time to let it speak and breathe its own air. It’s time for deep thoughts and severe time travel down the wormhole.</h1>
          <br></br>
          <h1 class="wiggly-text">It’s been far too long. Exhale. Enjoy.” - clown</h1>
        </div>
      </div>

      <div className="app__introArrow">
        <img
          className="app__introArrowImg" 
          src={downArrow}
          alt=""
        ></img>
      </div>

      <div className="app__posts">
        <div className="app__postsLeft">
          {
            posts.map(({id, post}) => (
              <Post key={id} username={post.username} caption={post.caption} imageUrl={post.imageUrl}></Post>
            ))
          }
        </div>
        <div className="app__postsRight">
        </div>
      </div>

    <div className="app__footer">
      {user?.displayName ? (
              <ImageUpload username={user.displayName} />
            ): (
              <h3><center>only clown chooses who may upload to the knoto</center></h3>
      )}
    </div>

    </div>
  );
}

export default App;

// Sign Up (add line 170) >> <Button style={{backgroundColor: "#ff00d4"}} variant="contained" onClick={() => setOpen(true)}>Sign Up</Button>