import React from 'react';
import './Post.css';
import Avatar from '@mui/material/Avatar';
import proPic from './clown001b.jpg';

function Post({ username, caption, imageUrl }) {
  return (
    <div className="post">
        <div className="post__header">
            <Avatar alt={username} src={proPic}/>
            <div className="post__header">
                <h3>{username}</h3>
            </div>
        </div>
        <img className="post__image" src={imageUrl} alt=""/>
        <h4 className="post__text"><strong className="post__text-strong">{username}:</strong> {caption}</h4>
    </div>
  )
}

export default Post; 