import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyApZY3JpabYP3n95QPj1OeKsm30xfqSPVo",
  authDomain: "knoto-201d9.firebaseapp.com",
  projectId: "knoto-201d9",
  storageBucket: "knoto-201d9.appspot.com",
  messagingSenderId: "899777978755",
  appId: "1:899777978755:web:1796cdec581c253a6416b6",
  measurementId: "G-1CQTXJGWSN"
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };